/** @jsx jsx */
import { Field } from "react-final-form"

import { FieldError } from "@trueskin-web/components"
import { jsx } from "@trueskin-web/theme"

const ErrorCondition = ({ name, className }) => (
  <Field name={name} subscription={{ error: true }}>
    {({ meta: { error } }) =>
      error ? <FieldError className={className}>{error}</FieldError> : null
    }
  </Field>
)

export default ErrorCondition
