/** @jsx jsx */
import { jsx } from "@trueskin-web/theme"

const GridDesktop = ({ cols = 2, gap = 5, ...otherProps }) => (
  <div
    sx={{
      display: "grid",
      gridTemplateColumns: ["auto", `repeat(${cols}, 1fr)`],
      gap: [null, gap],
    }}
    {...otherProps}
  />
)

export default GridDesktop
