/** @jsx jsx */
import { jsx } from "@trueskin-web/theme"
import { americanExpressIcon } from "@trueskin-web/theme/icons/americanExpressIcon"
import { amexIcon } from "@trueskin-web/theme/icons/amexIcon"
import { applePayIcon } from "@trueskin-web/theme/icons/applePayIcon"
import { eloIcon } from "@trueskin-web/theme/icons/eloIcon"
import { hyperCardIcon } from "@trueskin-web/theme/icons/hyperCardIcon"
import { klarnaIcon } from "@trueskin-web/theme/icons/klarnaIcon"
import { mastercardIcon } from "@trueskin-web/theme/icons/mastercardIcon"
import { paypalIcon } from "@trueskin-web/theme/icons/paypalIcon"
import { questionOutlineIcon } from "@trueskin-web/theme/icons/questionOutlineIcon"
import { sepaIcon } from "@trueskin-web/theme/icons/sepaIcon"
import { visaIcon } from "@trueskin-web/theme/icons/visaIcon"

import BrandLogo, { sizes as brandLogoSizes } from "../brand-logo"
import Spinner from "../spinner"

const PaymentLogo = ({ size, payment, isLoading }) => {
  const icon =
    {
      applePay: applePayIcon,
      paypal: paypalIcon,
      klarna: klarnaIcon,
      sepaDirectDebit: sepaIcon,
      card: {
        mc: mastercardIcon,
        visa: visaIcon,
        elo: eloIcon,
        hipercard: hyperCardIcon,
        amex:
          process.env.GATSBY_I18N_LOCALE === "pt-BR"
            ? amexIcon
            : americanExpressIcon,
      }[payment?.card?.paymentMethod],
    }[payment?.type] || questionOutlineIcon

  if (isLoading) {
    return (
      <Spinner
        sx={{
          display: "block",
          ...brandLogoSizes[size],
        }}
      />
    )
  }

  return <BrandLogo icon={icon} size={size} />
}

export default PaymentLogo
