/** @jsx jsx */
import { useId } from "@reach/auto-id"
import { forwardRef, useState } from "react"
import PhoneInput from "react-phone-input-2"
import "react-phone-input-2/lib/style.css"

import { getCountry } from "@trueskin-web/locales"
import { jsx, useThemeUI } from "@trueskin-web/theme"

import FormControl from "./form-control"
import FormErrorMessage from "./form-error-message"
import FormLabel from "./form-label"
import FormMessage from "./form-message"

const PhoneNumberInput = forwardRef(
  ({ input, meta, required, label, message, className, ...props }, ref) => {
    const id = useId()
    const {
      theme: { colors },
    } = useThemeUI()

    const [country] = useState(getCountry().toLocaleLowerCase())

    const { onChange, ...inputProps } = input
    const isInvalid =
      (meta.error || (meta.submitError && !meta.dirtySinceLastSubmit)) &&
      meta.touched

    return (
      <FormControl
        data-testid={props.dataTestId}
        isInvalid={isInvalid}
        isRequired={required}
        className={className}
      >
        {label && <FormLabel htmlFor={id}>{label}</FormLabel>}
        <PhoneInput
          country={country}
          inputStyle={{
            width: "100%",
            height: "40px",
            fontSize: "16px",
            lineHeight: "body",
            borderRadius: 0,
            borderColor: "initial",
            borderColor: isInvalid ? colors.attention : "initial",
          }}
          buttonStyle={{
            borderRadius: 0,
            borderColor: "initial",
            backgroundColor: "transparent",
            borderColor: isInvalid ? colors.attention : "initial",
          }}
          onChange={(value) => onChange(`+${value}`)}
          ref={ref}
          {...inputProps}
          {...props}
        />
        <FormErrorMessage>{meta.error || meta.submitError}</FormErrorMessage>
        <FormMessage>{message}</FormMessage>
      </FormControl>
    )
  }
)

export default PhoneNumberInput
