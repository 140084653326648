/** @jsx jsx */
import { jsx } from "@trueskin-web/theme"

import Label from "./label"

const ReadonlyField = ({ label = "\u00A0", value = "\u00A0", ...props }) => (
  <div {...props}>
    {label && (
      <Label
        sx={{
          display: "block",
          fontFamily: "body",
          fontSize: 2,
        }}
      >
        {label}
      </Label>
    )}
    {value && (
      <div
        data-testid="web-portal-readonly-field"
        sx={{
          fontFamily: "monospace",
          fontSize: 1,
        }}
      >
        {value}
      </div>
    )}
  </div>
)

export default ReadonlyField
