/** @jsx jsx */
import { Box, jsx } from "@trueskin-web/theme"
import { closeIcon } from "@trueskin-web/theme/icons/closeIcon"

import Button from "./button"
import Icon from "./icon"

const NotificationAction = ({
  bg,
  color = "#000000",
  closeButton,
  children,
  onClose = () => {},
  ...rest
}) => (
  <Box
    sx={{
      position: "relative",
      m: 0,
      px: 4,
      py: 5,
      bg,
      color,
    }}
    {...rest}
  >
    {closeButton && (
      <Button
        variant="outline"
        sx={{
          position: "absolute",
          top: 2,
          right: 2,
          size: "14px",
          m: 0,
          p: 0,
          borderRadius: "50%",
          borderColor: color,
          color,
          "&:hover": {
            background: "none transparent",
            borderColor: color,
            color,
          },
          "&:active, &:focus": {
            background: "none transparent",
            borderColor: color,
            color,
          },
        }}
        onClick={() => onClose()}
      >
        <Icon icon={closeIcon} size="xsm" />
      </Button>
    )}
    {children}
  </Box>
)

export default NotificationAction
