/** @jsx jsx */
import { forwardRef } from "react"

import { jsx } from "@trueskin-web/theme"

import { useFormControl } from "./form-control"

const Textarea = forwardRef((props, ref) => {
  const {
    isReadOnly,
    isFullWidth = true,
    isDisabled,
    hoverBorderColor,
    ...otherProps
  } = props
  const formControl = useFormControl(props)

  return (
    <textarea
      ref={ref}
      aria-invalid={formControl.isInvalid}
      readOnly={formControl.isReadOnly}
      aria-readonly={isReadOnly}
      disabled={formControl.isDisabled}
      aria-disabled={formControl.isDisabled}
      sx={{
        width: isFullWidth ? "100%" : undefined,
        resize: "none",
        appearance: "none",
        outline: "none",
        fontFamily: "inherit",
        fontSize: 2,
        lineHeight: "body",
        border: "1px",
        borderRadius: 0,
        color: "inherit",
        bg: "#fff",
        transition: "all .1s ease-in",
        "&:hover": {
          borderColor: hoverBorderColor ? hoverBorderColor : "primary",
        },
        "&:focus": {
          zIndex: 1,
          borderColor: hoverBorderColor ? hoverBorderColor : "primary",
          boxShadow: (theme) =>
            `0 0 0 1px ${
              hoverBorderColor ? hoverBorderColor : theme.colors.primary
            }`,
        },
        "&:disabled, &:disabled:focus, &:disabled:hover": {
          borderColor: "inherit",
          color: "disabled",
          cursor: "not-allowed",
        },
        "&[aria-invalid=true]": {
          borderColor: "attention",
        },
        "&[aria-invalid=true]:focus": {
          boxShadow: (theme) => `0 0 0 1px ${theme.colors.attention}`,
        },
      }}
      {...otherProps}
    />
  )
})

export default Textarea
