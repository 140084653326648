/** @jsx jsx */
import { useMemo } from "react"

import { paymentClient, useQuery } from "@trueskin-web/apis"
import { jsx } from "@trueskin-web/theme"

import PaymentBanner from "./payment-banner"
import PaymentLogo from "./payment-logo"

const PaymentVendor = ({ size, asBanner }) => {
  const { data: { data } = {}, isFetching } = useQuery(
    "primaryPaymentMethod",
    paymentClient.getPrimaryPaymentMethod
  )

  const Component = useMemo(
    () => (asBanner ? PaymentBanner : PaymentLogo),
    [asBanner]
  )

  return <Component size={size} payment={data} isLoading={isFetching} />
}

export default PaymentVendor
