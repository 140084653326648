/** @jsx jsx */
import { forwardRef, useImperativeHandle, useState } from "react"

import { jsx } from "@trueskin-web/theme"
import i18next from "@trueskin-web/translations"

import Button from "./button"
import EditableForm from "./editable-form"
import ReadonlyField from "./readonly-field"

const EditableField = forwardRef(
  (
    {
      variant = "link",
      action = i18next.t("Button.edit"),
      label = "\u00A0",
      value = "\u00A0",
      editable,
      onSubmit,
      onDelete,
      onClick,
      form,
      ...props
    },
    ref
  ) => {
    const [isEditing, setIsEditing] = useState(false)

    useImperativeHandle(ref, () => ({
      toggleEditing: () => setIsEditing(!isEditing),
      isEditing: !isEditing,
    }))

    return (
      <div {...props}>
        {isEditing ? (
          onSubmit ? (
            <EditableForm
              editable={editable}
              onSubmit={onSubmit}
              onDelete={onDelete}
              onSuccess={() => {
                setIsEditing(false)
              }}
              {...form}
            />
          ) : (
            editable
          )
        ) : (
          <ReadonlyField label={label} value={value} />
        )}

        <Button
          variant={isEditing ? "link" : variant}
          size="lg"
          onClick={
            onClick
              ? onClick
              : () => {
                  setIsEditing(!isEditing)
                }
          }
          sx={{
            display: "flex",
            mt: 1,
            px: isEditing || variant === "link" ? 0 : undefined,
            width: [isEditing || variant !== "link" ? "100%" : "auto", "auto"],
            textTransform: variant === "link" && "uppercase",
            fontSize: isEditing || variant === "link" ? 0 : 1,
            fontWeight: "bold",
            letterSpacing: "0.2em",
          }}
        >
          {isEditing ? i18next.t("Button.cancel") : action}
        </Button>
      </div>
    )
  }
)

export default EditableField
