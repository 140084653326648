/** @jsx jsx */
import {
  AlertDialogDescription,
  AlertDialogLabel,
  AlertDialog as ReachAlertDialog,
} from "@reach/alert-dialog"
import { useRef } from "react"

import { jsx } from "@trueskin-web/theme"
import { closeIcon } from "@trueskin-web/theme/icons/closeIcon"

import Button from "./button"
import IconButton from "./icon-button"

const AlertDialog = ({
  title,
  children,
  confirmLabel,
  cancelLabel,
  onConfirm,
  onCancel,
  onDismiss,
  noBackdropDismiss,
  noMobileFullscreen,
  ...otherProps
}) => {
  const focusRef = useRef()

  return (
    <ReachAlertDialog
      sx={{
        position: "relative",
        height: noMobileFullscreen ? "auto" : undefined,
        my: noMobileFullscreen ? "5vh" : undefined,
        py: 6,
        px: 5,
      }}
      onDismiss={noBackdropDismiss ? undefined : onDismiss || onCancel}
      leastDestructiveRef={focusRef}
      {...otherProps}
    >
      {(onDismiss || onCancel) && (
        <div sx={{ position: "absolute", top: 0, right: 0 }}>
          <IconButton
            ref={focusRef}
            icon={closeIcon}
            onClick={onDismiss || onCancel}
            sx={{
              p: [1, 2],
            }}
          />
        </div>
      )}

      {title && (
        <AlertDialogLabel sx={{ mb: 2, fontSize: 5 }}>{title}</AlertDialogLabel>
      )}

      <AlertDialogDescription sx={{ mb: [5, 7] }}>
        {children}
      </AlertDialogDescription>

      <div
        sx={{
          display: "grid",
          gridTemplateColumns: ["auto", "repeat(2, 1fr)"],
          gap: [4, 5],
        }}
      >
        <Button size="lg" variant="outline" onClick={onConfirm}>
          {confirmLabel}
        </Button>

        <Button size="lg" onClick={onCancel}>
          {cancelLabel}
        </Button>
      </div>
    </ReachAlertDialog>
  )
}

export default AlertDialog
